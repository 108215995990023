<template>
  <div
    class="column-container et-prize-column"
    :class="{
      'et-prize-column-active': isActive
    }"
  >
    <a
      href="#pricing"
      class="bf-prize column card scroll-to-element cta-exit"
      :class="{
        'bf-prize-past accent-red': isGone,
        'bf-prize-future accent-blue': isComing,
        'bf-prize-current accent-green': isActive && !isCheckout
      }"
    >
      <div v-if="!isCheckout" class="bf-badge-wrapper">
        <span
          :tooltip="tooltip"
          :tooltip-cta="cta"
          class="badge bf-with-tooltip gradient-background"
        >
          <img
            alt="More information"
            height="18"
            width="18"
            src="/images/black-friday/2022/info.svg"
          />
          <span>{{ labelText }}</span>
        </span>
      </div>

      <slot name="logo"></slot>

      <p v-if="!isCheckout">
        <slot></slot>
      </p>

      <div v-else>
        <h2 class="card-title">Plus Get This Free Prize!</h2>
        <span>+1 Year Support And Updates</span>
      </div>

      <div
        v-if="isActive"
        class="bf-prize-current-label centered card-inset-container"
      >
        <span class="headline-4">
          {{ left }}
          <span class="bf-prize-qty"> Remaining</span>
        </span>

        <portal to="currentPrize">
          <div class="row-container wide-width" ref="currentPrizeBox">
            <div class="row current-prize-subhead">
              <div class="column-container">
                <div class="column">
                  <h4 class="subhead">
                    Current Free Prize. <span> Only {{ left }} Remaining!</span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="row current-prize">
              <div class="column-container">
                <div class="column">
                  <slot name="logo-full"></slot>
                </div>
              </div>
              <div class="column-container">
                <div class="column accent-purple">
                  <div class="bf-badge-wrapper">
                    <span
                      class="badge bf-with-tooltip gradient-background"
                      tooltip="The prize is now active! Get this prize for free with your applicable Divi purchase."
                      tooltip-cta="Claim this prize right now"
                    >
                      <img
                        alt="More information"
                        height="18"
                        width="18"
                        src="/images/black-friday/2022/info.svg"
                      >
                      <span>Now Available</span>
                    </span>
                  </div>
                  <h2 class="headline-4">
                    Buy Now And Get <slot name="raw-title"></slot> For Free!
                  </h2>
                  <slot></slot>
                  <a
                    href="#pricing"
                    class="button inline-button accent-purple scroll-to-element cta-exit"
                  >
                    Claim This Prize Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </portal>

        <portal to="currentPrizeCount">
          <span v-if="this.$parent.productsLoaded" ref="currentPrizeBox">
            {{ left }}
          </span>
        </portal>

        <portal to="currentPrizeRemaining">
          <span v-if="this.$parent.productsLoaded" ref="currentPrizeBox">
            <span>{{ left }}</span>
          </span>
        </portal>

        <portal to="currentPrizeImage">
          <span v-if="this.$parent.productsLoaded" ref="currentPrizeBox">
            <slot name="logo-full"></slot>
          </span>
        </portal>

        <portal to="currentPrizeName">
          <span v-if="this.$parent.productsLoaded" ref="currentPrizeBox">
            <slot name="raw-title"></slot>
          </span>
        </portal>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isCheckout: false,
      left: null
    }
  },
  props: {
    amount: Number
  },
  computed: {
    labelText() {
      if (this.isActive) {
        return 'Now Available'
      } else if (this.isGone) {
        return 'Sold Out'
      } else {
        return 'Coming Soon'
      }
    },
    isGone() {
      return this.left === 0
    },
    isComing() {
      return !this.isActive && this.left === this.amount
    },
    tooltip() {
      if (this.isActive) {
        return 'The prize is now active! Get this prize for free with your applicable Divi purchase.'
      } else if (this.isGone) {
        return "This free prize is sold out! Don't worry, there are still more prizes available. You can also buy this product on the Divi Marketplace right now and get a huge discount!"
      } else {
        return "This prize isn't available yet. Prizes are given away in small batches in the order shown in this list. Stand by to grab this prize. Don't let it get away!"
      }
    },
    cta() {
      if (this.isActive) {
        return 'Claim this prize right now'
      } else if (this.isGone) {
        return 'Claim the active prize instead'
      } else {
        return 'Get ready to claim this prize'
      }
    }
  }
}
</script>
