import Vue from 'vue'

import PortalVue from 'portal-vue'

import Product from './components/Product.vue'
import Products from './components/Products.vue'

Vue.config.productionTip = false

Vue.use(PortalVue)

Vue.component('product', Product)
Vue.component('products', Products)

if (document.getElementById('black-friday-app')) {
	new Vue({
		el: '#black-friday-app'
	})
}
