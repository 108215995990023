<template>
  <div
    id="prizes-grid"
    class="et_black_friday_products"
    v-bind:class="[
      { et_black_friday_products_loaded: !loading },
      `et_black_friday_products_${page}`
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
import clone from 'lodash/clone'

import firebase from 'firebase/app'
import 'firebase/database'

import axios from 'axios'

export default {
  data: () => ({
    loading: true,
    productsLoaded: false,
    sold: 0,
    ranges: [],
    products: [],
    disableFirestore:
      typeof window.et_disable_firestore !== 'undefined' &&
      window.et_disable_firestore,
    localDBCheckPeriod:
      typeof window.et_local_db_check_period !== 'undefined'
        ? parseInt(window.et_local_db_check_period)
        : 60
  }),
  props: {
    page: String,
    isCheckout: {
      type: Boolean,
      default: false
    },
    isCmSale: {
      type: Boolean,
      default: false
    }
  },
  created() {
    let _this = this

    if (_this.disableFirestore) {
      _this.setupLocalAPICheck()

      return
    }

    let etProductsRef

    let switchedToBackupDb = false

    let firebaseMainDbUrl = ''
    let firebaseBackupDbUrl = ''

    firebaseMainDbUrl = !this.isCmSale
      ? 'https://fir-sale.firebaseio.com'
      : 'https://fir-sale-487b4.firebaseio.com/'
    firebaseBackupDbUrl = !this.isCmSale
      ? 'https://fir-sale-998f8.firebaseio.com'
      : 'https://fir-sale-6cc5e.firebaseio.com/'

    // connect to the main database
    let db = firebase
      .initializeApp({
        databaseURL: firebaseMainDbUrl
      })
      .database()

    const productsSoldHandler = snapshot => {
      _this.sold = snapshot.val()

      _this.disableLoading()
    }

    // connect to the product reference on the current database
    const connectToProductsRef = (reconnect = false) => {
      if (reconnect) {
        etProductsRef.off('value', productsSoldHandler)
      }

      etProductsRef = db.ref('products')

      etProductsRef.on('value', productsSoldHandler)
    }

    const connectToBackupDatabase = () => {
      const app2 = firebase.initializeApp(
        {
          databaseURL: firebaseBackupDbUrl
        },
        'app2'
      )

      db = firebase.database(app2)

      connectToProductsRef(true)

      switchedToBackupDb = true
    }

    // connect to backup database, if we hit the maximum concurrent connections limit
    firebase.database.enableLogging(message => {
      if (!switchedToBackupDb && message.includes('server_kill')) {
        connectToBackupDatabase()
      }
    })

    connectToProductsRef()
  },
  mounted() {
    this.products = this.$children

    this.setProductsProps()

    this.setRanges()
  },
  updated() {
      this.productsLoaded = true
  },
  computed: {
    activeProductIndex() {
      let productIndex

      let _ranges = this.leftRanges

      for (let i = 0; i < _ranges.length; i++) {
        if (_ranges[i] > 0) {
          productIndex = i
          break
        }
      }

      return productIndex
    },

    leftRanges() {
      let _ranges = clone(this.ranges)

      let _sold = this.sold

      for (let i = 0; i < _ranges.length; i++) {
        if (_sold <= 0) {
          break
        }

        let amount = _ranges[i]

        amount -= _sold

        _sold -= _ranges[i]

        if (amount < 0) {
          amount = 0
        }

        _ranges[i] = amount
      }

      return _ranges
    }
  },
  watch: {
    activeProductIndex(activeProductIndex) {
      this.products.map(
        (product, index) => (product.isActive = activeProductIndex === index)
      )
    },
    leftRanges(ranges) {
      this.products.map((product, index) => (product.left = ranges[index]))
    }
  },
  methods: {
    getLocalDBProductsCount() {
      const _this = this

      let data = new FormData()

      data.append('type', _this.page)

      axios.post(window.et_local_db_api_endpoint, data).then(response => {
        if (typeof response.data.count !== 'undefined') {
          _this.sold = parseInt(response.data.count)

          _this.disableLoading()
        }
      })
    },
    disableLoading() {
      if (this.loading) {
        this.loading = false
      }
    },
    setupLocalAPICheck() {
      const _this = this

      this.getLocalDBProductsCount()

      setInterval(
        _this.getLocalDBProductsCount,
        _this.localDBCheckPeriod * 1000
      )
    },
    setRanges() {
      this.ranges = this.products.map(product => product.amount)
    },
    setProductsProps() {
      const _this = this

      this.products.map(product => (product.isCheckout = _this.isCheckout))
    }
  }
}
</script>
